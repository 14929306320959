<template>
  <ul
    class="m-brand-menu-list"
    :class="isDesktop && !isMobile ? 'm-brand-menu-list--desktop' : 'm-brand-menu-list--mobile'"
  >
    <MRenderImageMenu
      v-for="(brand, i) in items.slice(0,5)"
      :key="i"
      :style="`order: ${brand.position}`"
      :brand="brand"
      @go-to="goToBrandPage(brand.url_key)"
    />
  </ul>
</template>
<script>
import { isServer } from '@vue-storefront/core/helpers';
import DeviceType from 'theme/mixins/DeviceType'
import MRenderImageMenu from 'theme/components/molecules/m-render-image-menu'
import { formatRegionUrl } from '$modules/shipping/utils/region-url';

export default {
  name: 'MBrandMenu',
  components: {
    MRenderImageMenu
  },
  mixins: [DeviceType],
  props: {
    items: {
      type: [],
      default: () => {
        return []
      }
    }
  },
  data () {
    return {
      isMobileMenu: false
    }
  },
  mounted () {
    this.isMobileMenu = this.$store.state.ui.isMobileCatalog
  },
  methods: {
    goToBrandPage (link) {
      if (!isServer && this.isMobileMenu) {
        const getCategoryButton = document.querySelector('.sf-bottom-navigation-item__catalog')
        if (getCategoryButton) {
          getCategoryButton.click()
        }
      }

      this.$router.push(this.localizedRoute(formatRegionUrl(`/${link}`)))
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~theme/css/breakpoints";
@import "~@storefront-ui/shared/styles/helpers/breakpoints";
@import "~theme/css/px2rem";

.m-brand-menu-list {
  display: flex;
  position: static;
  padding: 0 0 var(--spacer-15) 0;
  flex-wrap: wrap;
  column-gap: 1.5rem;

  @media (min-width: $tablet-min) {
    column-gap: 2.5rem;
  }

  &--mobile {
    justify-content: center;
    padding-top: var(--spacer-30);

    li:nth-child(-n+2) {
      display: flex;
    }

    li {
      display: none;
    }
  }

  &--desktop {
    position: absolute;
    bottom: 0;

    li {
      display: flex;
    }
  }

  &__image {
    overflow: unset;
    height: 5rem;
  }

  li {
    cursor: pointer;
    align-items: center;
    overflow: hidden;
    padding: 0.5rem 0;
    width: 147px;
    margin-right: var(--spacer-5);
    justify-content: center;

    @include for-desktop {
      max-width: 113px;
    }

    ::v-deep img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    &:last-of-type {
      margin-right: 0;
    }
  }
}
</style>
